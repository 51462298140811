import JordyMain from "../images/hero/meyecv.png";

import {  FaGithub, FaInstagram } from 'react-icons/fa';

import icons1 from "../images/icons/icons1.png";
import icons5 from "../images/icons/icons5.png";
import icons10 from "../images/icons/icons10.png";
import icons11 from "../images/icons/icons11.png";
import icons12 from "../images/icons/icons12.png";
import icons13 from "../images/icons/icons13.png";

import wave from "../images/hero/wave.png";

import { useEffect, useState } from "react";
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faJs, faReact, faHtml5, faCss3 } from '@fortawesome/free-brands-svg-icons'; 

function Hero() {
  const [goUp, setGoUp] = useState(false);

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  useEffect(() => {
    const onPageScroll = () => {
      if (window.pageYOffset > 600) {
        setGoUp(true);
      } else {
        setGoUp(false);
      }
    };
    window.addEventListener("scroll", onPageScroll);

    return () => {
      window.removeEventListener("scroll", onPageScroll);
    };
  }, []);

  return (
    <>
      <section id="hero" className="hero-section">
        <div className="container">
          <div className="hero-content">
            <div className="hero-content__text">
              <h1>Full-stack Software <br /> Engineer   <img className="wave" src={wave} alt="wave"  style={{ width: "20%", maxWidth: "50px" }} /> </h1>
              <p>Hi, I am Jordy Meye. A passionate software Engineer <br /> based in Cape Town South Africa📍. </p>
                
              <div className="social-icons">
              {/* <a href="#" target="_blank" rel="noreferrer">
                <FontAwesomeIcon icon={faGithub}  size="2x" style={{ marginRight: '15px' }} />
              </a> */}
               <a href="https://github.com/JordyMeye" target="_blank" rel="noopener noreferrer" style={{ color: '#000', fontSize: '30px', marginRight: '7px', transition: 'color 0.3s ease-in-out' }}>
                  <FaGithub />
                </a>
                <a href="https://instagram.com/jordyxx__" target="_blank" rel="noopener noreferrer" style={{ color: '#000', fontSize: '30px', transition: 'color 0.3s ease-in-out' }}>
                  <FaInstagram />
                </a>
            </div>

              </div>
            <div className="hero-content__main-img" >
              <img src={JordyMain} alt="main-img" />
              </div>
            
            </div>

              <div className="tech-stack-icons">
                <p> Tech Stack  </p>
                {/* <div className="icon"> */}


                                              <lu>
                                <li>
                                  <img src={icons1} alt="icons1" />
                                </li>
                                <li>
                                  <img src={icons10} alt="icons10" />
                                </li>
                                <li>
                                  <img src={icons11} alt="icons11" />
                                </li>
                                <li>
                                  <img src={icons12} alt="icons12" />
                                </li>
                                <li>
                                  <img src={icons13} alt="icons13" />
                                </li>
                                <li>
                                  <img src={icons5} alt="icons5" />
                                </li>
                              </lu>

              </div>
            
       
        </div>
      </section>
    </>
  );
}

export default Hero;
