
import NavBar from "./component/NavBar";

import Home from "./pages/Home";
import About from "./pages/About";
import Projects from "./pages/Projects";
import Contact from "./pages/Contact";


import './Styles/index.scss';
import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
import "/node_modules/bootstrap/dist/js/bootstrap.min.js";


import { Route, Routes } from "react-router-dom";

function App() {
  return (
    <>
      <NavBar />
      
      <Routes>

     
        

        <Route index path="/" element={<Home />} />
         <Route path="/about" element={<About />} />
         <Route path="projects" element={<Projects />} />
         <Route path="contact" element={<Contact />} />

        {/* <Route path="contact" element={<Contact />} />   */}

      </Routes>
      
    </>
  );
}


export default App;
