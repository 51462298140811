import Footer from "../component/Footer";

import About from "../pages/About";
import Projects from "../pages/Projects";
import Contact from "../pages/Contact";
import Hero from "../component/Hero";


function Home() {
  return (
    <>
      <Hero id="hero"/>
      <About id="about"/>

      <Projects id="projects" />
      <Contact id="contact" />
      <Footer />

     

      
    </>
  );
}

export default Home;
