import { Link } from "react-router-dom";
import { useState, useEffect } from "react";
import { Link as ScrollLink, animateScroll as scroll } from "react-scroll";

const NavBar = () => {
  const [nav, setNav] = useState(false);
  const [isSticky, setIsSticky] = useState(false);

  const openNav = () => {
    setNav(!nav);
  };

  
  const handleLinkClick = (to) => {
    // openNav();
    if (to === "home") {
     
      scroll.scrollToTop();
    }
  };


  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 50) {
        setIsSticky(true);
      } else {
        setIsSticky(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const scrollToTop = () => {
    scroll.scrollToTop();
  };

  return (
    <>
      <nav className={`navbar ${isSticky ? "sticky" : ""}`}>
        {/* Mobile navbar */}
        <div className={`mobile-navbar ${nav ? "open-nav" : ""}`}>
          <ul className="mobile-navbar__links">
            <li>
              <Link onClick={() => handleLinkClick("home")} to="/">
                Home
              </Link>
            </li>
            <li>
              <ScrollLink onClick={handleLinkClick} to="about" spy={true} smooth={true} offset={-70} duration={500}>
                About
              </ScrollLink>
            </li>
            <li>
              <ScrollLink onClick={handleLinkClick} to="projects" spy={true} smooth={true} offset={-70} duration={500}>
                Projects
              </ScrollLink>
            </li>
            <li>
              <ScrollLink onClick={handleLinkClick} to="contact" spy={true} smooth={true} offset={-70} duration={500}>
                Contact
              </ScrollLink>
            </li>
          </ul>
        </div>

        {/* Desktop navbar */}
        <div className="navbar">
          <div className="navbar__img">
          <Link to="/" onClick={() => handleLinkClick("home")} className="no-underline">
              <h3 className="logo">Jordy.Dev</h3>
            </Link>
          </div>
          <ul className="navbar__links">
            <li>
            <Link className="home-link" to="/" onClick={() => handleLinkClick("home")}>
                Home
              </Link>
            </li>
            <li>
              <ScrollLink className="about-link" to="about" spy={true} smooth={true} offset={-70} duration={500}>
                About
              </ScrollLink>
            </li>
            <li>
              <ScrollLink className="models-link" to="projects" spy={true} smooth={true} offset={-70} duration={500}>
                Projects
              </ScrollLink>
            </li>
            <li>
              <ScrollLink className="contact-link" to="contact" spy={true} smooth={true} offset={-70} duration={500}>
                Contact
              </ScrollLink>
            </li>
          </ul>

          {/* Mobile hamburger icon */}
          <div className="mobile-hamb" onClick={openNav}>
            <i className="fa-solid fa-bars"></i>
          </div>
        </div>
      </nav>
      {/* Add this div to push content down */}
      <div className={`push-down ${nav ? "open-nav" : ""}`}></div>
    </>
  );
};

export default NavBar;
