import React, { useState, useRef } from 'react';

import emailjs from '@emailjs/browser';


const Contact = () => {
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm('service_ybxfitr', 'template_stigdbd', form.current, 'Hh-sBxOkeV4onZY7x')
      .then(
        (result) => {
          console.log(result.text);
          console.log('Message Sent');
          setMessageSent(true); // 
          setFormData({ user_name: '', your_email: '', message: '' }); 
        },
        (error) => {
          console.log(error.text);
        }
      );
  };

  const [formData, setFormData] = useState({
    user_name: '',
    your_email: '',
    message: '',
  });

  const [messageSent, setMessageSent] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  return (
    <section className="contact-section" id="contact">
      <div className="container">
        <h2>Contact Me</h2>
        <h3>Don't be shy! Hit me up! 👇</h3>
        <form ref={form} onSubmit={sendEmail} className="contact-form">
          <label htmlFor="name"></label>
          <input
            type="text"
            id="name"
            name="user_name"
            placeholder="Your Name"
            value={formData.user_name}
            onChange={handleChange}
            required
          />

          <label htmlFor="email"></label>
          <input
            type="email"
            id="email"
            name="user_email"
            placeholder="Your Email"
            value={formData.user_email}
            onChange={handleChange}
            required
          />

          <label htmlFor="message"></label>
          <textarea
            id="message"
            name="message"
            placeholder="Your Message"
            rows="4"
            value={formData.message}
            onChange={handleChange}
            required
          ></textarea>

          <button type="submit">Send</button>
          {messageSent && <p style={{ color: 'green' }}>Message sent successfully!</p>}
        </form>
      </div>
    </section>
  );
};

export default Contact;