

import React from 'react';
import AboutMain from "../images/hero/techimg.jpg";
import RotatingImage from "../images/logoEngineer.png";
import centerImage from "../images/subject1.png";


function About()  {
  return (
    <section className="about-me-container" id="about">
      <div className="about-me-content">
        <div className="description">
          <h2>About Me</h2>
          <p>
            <h1> A dedicated sofware developper from <br/> cape town, South Africa📍</h1>
        
             Hi, I'm Jordy Meye, a full-stack developer based in Cape Town, South Africa . 
             Beyond the world of coding, I thrive on the football field, where the intricate teamwork of football
             has imparted valuable lessons. Just as line of code come together to create something 
             powerful, each player contributes to a collective effort. This dual experience 
             has melded me into an adept team player, whether orchestrating plays on the pitch 
             or collaborating effectively in coding projects. The parallels between soccer and 
             coding have instilled in me the significance of unity, communication, and shared goals,
              shaping my approach to both realms with a commitment to collective success.
          </p>

            
        </div>
        <div className="image-container">
        <img src={AboutMain} alt="main-img" className="about-content__main-img" />
        <img src={RotatingImage} alt="rotating-img" className="about-content__main-img rotating-image" />
        <img src={centerImage} alt="Center Image" className="center-image" />
        </div>
      </div>
    </section>
  );
};

export default About;
