import React from 'react';
import Project1Image from '../images/truck.png'; 
import Project2Image from '../images/e-com.png'; 
import gitIcons from '../images/Git.png';
import shareicons from '../images/share.png';

function Projects() {
    return (
      <div className="project-content" id="projects">
        <div className="header">
          <p>portfolio</p>
          <h3>Each project is a unique piece of development 🧩</h3>
        </div>
        <div className="projects-grid">

          {/* Commenting out the project details
          <div className="project1">
            <div className="project-left">
              <img src={Project1Image} alt="Project 1" />
            </div>
            <div className="project-right">
              <h2> Truck Rental <span className='date-class'>(June 2023)</span> 🚚</h2>
              <p>
                A truck rental website is an online platform
                that allows users to rent 
                trucks for personals or business use.
                the website provides an internal for searching, 
                comparing and reserving trucks
              </p>
              <div className="stack">
                <p>SpringBoot</p>
                <p>React</p>
                <p>SCSS</p>
                <p>SQL</p>
              </div>
              <div className="icons">
                <a href="https://github.com/JordyMeye/truck-rental"> code</a>
                <img src={gitIcons} className="github-icon" alt="Git-hub" />
                <a href="https://truck-rental.vercel.app/"> Live Demo </a>
                <img src={shareicons} className="share-icon" alt="share-i" />
              </div>
            </div>
          </div>

          <div className="project1">
            <div className="project-left">
              <img src={Project2Image} alt="Project 1" />
            </div>
            <div className="project-right">
              <h2> E-Commerce <span className='date-class'>(October 2023)</span> 🛒</h2>
              <p>
                E-Commerce platform offers a seamless shopping experience for users 
                seeking a wide range of products. From trendy fashion items 
                to cutting-edge electronics.
              </p>
              <div className="stack">
                <p>Django</p>
                <p>React</p>
                <p>CSS</p>
                <p>MangoDB</p>
              </div>
              <div className="icons">
                <a href="https://github.com/JordyMeye/E-Commerce"> code</a>
                <img src={gitIcons} className="github-icon" alt="Git-hub" />
                <a href="https://e-commerce-phi-puce.vercel.app/"> Live Demo </a>
                <img src={shareicons} className="share-icon" alt="share-i" />
              </div>
            </div>
          </div>
          */}

          <div className='Attendant'>
            <h2 className="new-projects">More Projects Coming Soon.. 🤠</h2>
          </div>

        </div>
      </div>
    );
}

export default Projects;
